@import "Assets/fonts/fonts.css";

html, body {
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
  background-color: #f9f9fd;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /*background: linear-gradient(180deg, #00c6fb, #005bea);*/
  background: #aaa;
}


body {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  background-color: #EEEEEE;
  overflow-y: scroll;
}

p {
  font-size: 20px;
  line-height: 24px;
  color: #000;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-top: 1.5rem;
}

.container {
  max-width: 945px;
  margin: 0 auto;
  padding: 30px 15px;
}




img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  border-radius: 5px;
}



.header{
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: static;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background-color: white;
  margin-bottom: 28px;
  height: 60px;
}

.header-logo {
  width: 325px;
  height: 50px;
  cursor: pointer;
}

.header-logo__logo {
  margin: 5px 0;
  border-radius: 0;
}

#header-input__text {
  height: 50px;
  width: 600px;
  margin: 5px 30px 0 30px;
  font-weight: 600;
  font-size: 16px;
  color: #787878;
  background: #E2E1E1;
  border-radius: 5px;
  border: none;

}

#header-input__text:focus {
  outline: none;
}

.header-button {
  display: flex;
  margin: 5px 0 5px 500px;
}

.header-button_reg {
  cursor: pointer;
  width: 150px;
  height: 50px;
  border: 1px solid #1D28FA;
  box-sizing: border-box;
  border-radius: 5px;
  margin-right: 10px;
}

.header-button_reg-text {
  text-align: center;
  color: #1D28FA;
  font-weight: 600;
  font-size: 16px;
  margin: 12px 0 12px 0;
}

.header-button_log {
  cursor: pointer;
  width: 110px;
  height: 50px;
}

.header-button_log-text {
  text-align: center;
  color: #000;
  font-weight: 600;
  font-size: 16px;
  margin: 12px 0 12px 0;

}

.article-header {
  font-size: 36px;
}

/*Main Page*/

.main-container {
  cursor: pointer;
  width: 866px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 5px;
  margin: auto auto 30px;
  transition: box-shadow 0.5s, transform 0.5s;
  background-color: #fff;

}


.main-container:hover {
  transform: scale(1.02);
  box-shadow: 0 0 15px 7px rgba(34, 60, 80, 0.2);
  transition: box-shadow 0.5s, transform 0.5s;
}

.main-container__content {
  width: 806px;
  margin: 30px;
}

.author {
  display: flex;
}

.author-avatar-image {
  width: 32px;
  height: 32px;
  border-radius: 70px;
}



.author-name {
  font-size: 18px;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 400px;
}

.article-tags {
  display: flex;
}

.mainDate {
  /*margin-left: 400px;*/
  margin-top: 5px;
}

.article-tags__img {
  margin-top: 2px;
  margin-left: 5px;
  margin-right: 5px;
  width: 20px;
  height: 20px;


}

.article-tags__text {
  font-size: 18px;
  font-weight: normal;
  color: #AEAEAE;
  margin-top: 0;
  margin-bottom: 7px;
}


/*Article page*/




.article-container {
  width: 945px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #fff;
}



.article-container__content {
  width: 885px;
  margin: 30px;
}

.articleDate {
  margin-top: 5px;
  margin-left: 75px;
}


/* NotFound page*/


.notFound-page {
  min-height: 100vh;
  background-color: #0085FF;
}

.notFound-container {
  max-width: 1056px;
  max-height: 760px;
  padding-top: 162px;
  padding-left: 195px;
}

.notFound__smile-img {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  border-radius: 0px;
}

.notFound__title-text {
  font-family: "Inter", sans-serif;
  font-weight: normal;
  font-size: 50px;
  margin-top: 30px;
  color: #fff;
}

.notFound-button {
  height: 66px;
  width: 250px;
  margin-top: 60px;
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.notFound-goBack__btn {
  font-family: "Inter", sans-serif;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  padding-top: 20px;
  line-height: 29px;
  color: #0085FF;
}

.notFound-bottom {
  margin-top: 60px;
  display: flex;
  flex-direction: row;
}

.notFound-QR__img {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  border-radius: 0px;
}

.notFound-error_text {
  font-family: "Inter", sans-serif;
  font-weight: 100;
  font-size: 16px;
  color: #fff;
  margin-left: 14px;
  margin-top: 0;
  line-height: 19px;
}

.notFound-error__link {
  text-decoration: none;
  color: #fff;
  margin-left: 5px;
}

.notFound-error_link:active {
  text-decoration: none;
}

.notFound-support_text {
  font-family: "Inter", sans-serif;
  font-weight: 100;
  font-size: 16px;
  color: #fff;
  margin-left: 14px;
  margin-top: 100px;
  line-height: 19px;
}

.notFound-errorCode_text {
  font-family: "Inter", sans-serif;
  font-weight: 100;
  font-size: 16px;
  color: #fff;
  margin-left: 14px;
  margin-top: 20px;
  line-height: 19px;

}


/*Registration*/

.wrapper {
  background-image: url("../src/Assets/images/bckgrnd.png");
  background-repeat: no-repeat;
  min-height: 100vh;
}



.registration-container {

  max-width: 945px;

  padding: 220px 710px 50px;



}

.registration__background {
  position: absolute;
  width: 500px;
  height: 678px;
  background: #0085FF;
  border-radius: 5px;
  z-index: -999;
  margin-left: 30px;
}

.registration {
  width: 500px;
  height: 650px;


  background: #FFFFFF;
  box-shadow: 15px 15px 30px 8px rgba(0, 0, 0, 0.35);
  border-radius: 5px;
}

.registration__logo {
  width: 305px;
  height: 50px;
  margin-left: 97px;
  padding-top: 20px;
}

.registration__logo:hover {
  cursor: pointer;
}

.registration__logo-img {
}

.registration-header__text {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  margin-top: 48px;
  margin-left: 105px;
  color: #000000;
}



.form-registration {
  height: 317px;
  width: 295px;
  margin-left: 105px;
}

.form-registration__input {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  border: none;
  margin-top: 30px;
}

.form-registration__input:active, :hover, :focus {
  outline: none;
}

.registration__button {
  width: 180px;
  height: 45px;
  background: #0085FF;
  border-radius: 5px;
  margin-left: 160px;
  margin-top: 40px;
}

.registration__button:hover {
  cursor: pointer;
}

.registration__button-text {
  padding-top: 10px;
  text-align: center;
  color: #fff;
}

.checkAccount {
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 170px ;
}

.checkAccount-text {
  margin-top: 0;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
}


.checkAccount__Login {
  margin-top: 0;
  margin-left: 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  text-decoration: underline;
}

.checkAccount__Login:hover {
  cursor: pointer;
}


/*Login*/

.login-container {

  max-width: 945px;

  padding: 220px 710px 50px;



}

.login__background {
  position: absolute;
  width: 500px;
  height: 613px;
  background: #0085FF;
  border-radius: 5px;
  z-index: -999;
  margin-left: 30px;
}

.login {
  width: 500px;
  height: 583px;


  background: #FFFFFF;
  box-shadow: 15px 15px 30px 8px rgba(0, 0, 0, 0.35);
  border-radius: 5px;
}

.login__logo {
  width: 305px;
  height: 50px;
  margin-left: 97px;
  padding-top: 20px;
}

.login__logo:hover {
  cursor: pointer;
}

.login__logo-img {
}

.login-header__text {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  margin-top: 48px;
  margin-left: 105px;
  color: #000000;
}


.form-login {
  height: 250px;
  width: 295px;
  margin-left: 105px;
}

.form-login__input {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  border: none;
  margin-top: 50px;
}

.form-login__input:active, :hover, :focus {
  outline: none;
}

.login__button {
  width: 180px;
  height: 45px;
  background: #0085FF;
  border-radius: 5px;
  margin-left: 160px;
  margin-top: 40px;
}

.login__button:hover {
  cursor: pointer;
}

.login__button-text {
  padding-top: 10px;
  text-align: center;
  color: #fff;
}

.checkAccount {
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 170px ;
}

.checkAccount-text {
  margin-top: 0;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
}


.checkAccount__Login {
  margin-top: 0;
  margin-left: 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  text-decoration: underline;
}

.checkAccount__Login:hover {
  cursor: pointer;
}
























.control {
  margin-top: 30px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  padding-top: 3px;
  cursor: pointer;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control_indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #E2E1E1;
  border: 0px solid #000000;
  border-radius: 0px;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
  background: #AEAEAE;
}

.control input:checked ~ .control_indicator {
  background: #2aa1c0;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
  background: #0e6647d;
}
.control input:disabled ~ .control_indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.control_indicator:after {
  box-sizing: unset;
  content: '';
  position: absolute;
  display: none;
}
.control input:checked ~ .control_indicator:after {
  display: block;
}
.control-checkbox .control_indicator:after {
  left: 8px;
  top: 4px;
  width: 3px;
  height: 8px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.control-checkbox input:disabled ~ .control_indicator:after {
  border-color: #7b7b7b;
}
.control-checkbox .control_indicator::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 4.5rem;
  height: 4.5rem;
  margin-left: -1.3rem;
  margin-top: -1.3rem;
  background: #2aa1c0;
  border-radius: 3rem;
  opacity: 0.6;
  z-index: 99999;
  transform: scale(0);
}
@keyframes s-ripple {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
@keyframes s-ripple-dup {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(1);
  }
  60% {
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
.control-checkbox input + .control_indicator::before {
  animation: s-ripple 250ms ease-out;
}
.control-checkbox input:checked + .control_indicator::before {
  animation-name: s-ripple-dup;
}